import React from 'react';
import SelectorCapacidadContenido from './SelectorCapacidadContenido.jsx';
import { RiCloseFill } from 'react-icons/ri';
import { stringify } from 'rebem-classname';

export default function SelectorDePersonasParaModal(props){
	const block="SelectorDePersonasParaModal";
	return (
		<div className={stringify({block})}>
			<div className={stringify({block, elem:'contenedor'})}>
				<button className={stringify({block, elem:'cruz'})} onClick={props.ocultarModal}><RiCloseFill /></button>
				<span className={`btn-noButton ${stringify({block, elem:'texto'})}`} >Quienes</span>
				<button className={`btn-noButton ${stringify({block, elem:'restablecer'})}`} onClick={props.onRestablecerCapacidad} >Restablecer</button>
			</div> 
			<SelectorCapacidadContenido
				onPlus={props.onPlus}
				onMinus={props.onMinus}
				capacidades={props.capacidades}
				toggle={props.toggle}
			/>
			<div className={stringify({block, elem:'contenedor', mods:{guardar:true}})}>
				<button className={stringify({block, elem:'boton'})} onClick={props.ocultarModal}>Guardar</button>
			</div>
		</div>
	);
}