import React from 'react';
import {BsPlusCircle} from 'react-icons/bs';
import {BiMinusCircle} from 'react-icons/bi'
import { stringify } from 'rebem-classname'; 

export default function SelectorPlusMinus(props){
	const block="SelectorPlusMinus";

	return (
		<div className={stringify({block})}>
			<div className={stringify({block, elem:'personas'})}>
				<div className={stringify({block, elem:'titulo', mods:{bold:true}})}>
					<span>{props.titulo}</span>
				</div>
				<div className={stringify({block, elem:'leyenda'})}> 
					<span>{props.leyenda}</span>
				</div>
			</div>
			<div className={stringify({block, elem:'cantidad'})}>
				<button type="button" className={`btn-noButton ${stringify({block,  elem:"menos"})}`} data-name={props.nombreProp} onClick={props.onMinus} >
					<BiMinusCircle className={stringify({block, mods:{celesteGrande:true}})} />
				</button>
				<div className={stringify({block, elem:"valor"})}>
					<span className={stringify({block, elem:'valor-span'})}>{props.valor}</span>
				</div> 
				<button type="button" data-name={props.nombreProp} className={`btn-noButton ${stringify({block, elem:"mas"})}`} onClick={props.onPlus} >
					<BsPlusCircle  className={stringify({block, mods:{celesteGrande:true}})} />
				</button>
			</div>

		</div>
	);
}