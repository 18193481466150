import React from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { BsArrowRightShort } from 'react-icons/bs';
import moment from 'moment';
import CalendarHelper from '../../helpers/CalendarHelper.js';
import PrecioHelper from '../../helpers/PrecioHelper';
import { DayPickerRangeController} from 'react-dates';
import { START_DATE } from 'react-dates/constants';
import some from 'lodash/some';
import min from 'lodash/min';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isMobile from 'ismobilejs';
import { stringify } from 'rebem-classname';

export default class CalendarioParaModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.onFocusChange = this.onFocusChange.bind(this);
		this.isOutsideRange = this.isOutsideRange.bind(this);
		this.isDayBlocked = this.isDayBlocked.bind(this);
		this.isDayHighlighted = this.isDayHighlighted.bind(this);
		this.renderDay = this.renderDay.bind(this);
		this.calendarHelper = new CalendarHelper();
		this.state = {
			focusedInput:START_DATE,
		}
	}

	isOutsideRange(day){
		//deshabilita las fechas anteriores a mañana, o sea, de hoy para atrás
		return day.isBefore(moment(),'day');
	}

	onFocusChange(focusedInput) {
		this.setState({
			focusedInput: !focusedInput ? START_DATE : focusedInput
		});
		
		//una vez que elegió las 2 fechas, se cierra solo
		if(!focusedInput){
			this.props.ocultarModal();
		}
	}

	isDayHighlighted(day){
		const ocupaciones = this.props.ocupaciones;
		if(ocupaciones && ocupaciones.length){
			return some(ocupaciones,(o) => {
				return moment(o.fecha_inicio).isSame(day,'day') || moment(o.fecha_fin).isSame(day,'day');
			});
		}
		return false;
	}

	isDayBlocked(day){
		return false;
		const ocupaciones = this.props.ocupaciones;
		if(ocupaciones && ocupaciones.length){
			//existe una reserva que empiece en day?
			const empiezaAlgunaEnDia = this.calendarHelper.algunaOcupacionEmpiezaEn(ocupaciones, day);
			//si existe, existe una reserva que termine en day?
			const empiezaYTerminaEnDia = empiezaAlgunaEnDia && this.calendarHelper.algunaOcupacionTerminaEn(ocupaciones, day);

			//si empieza y termina, el día está bloqueado
			if(empiezaYTerminaEnDia){
				return true;
			}
			
			//si no, me fijo si está contenida en alguna reserva
			return some(ocupaciones,(o)=>{
				return moment(o.fecha_inicio).isBefore(day,'day') && moment(o.fecha_fin).isAfter(day,'day');
			});
		}
		return false;
	}

	renderDay(day){
		const { precios, ocupaciones } = this.props;
		const block="CalendarioParaModal";

		const precioPorFecha = min(map(filter(precios, p => moment(p.fecha).isSame(day, 'day'), 'precio')));

		const estaLibre = !this.isDayBlocked(day);
		let esComienzo = this.calendarHelper.algunaOcupacionEmpiezaEn(ocupaciones, day);
		let esFinal = this.calendarHelper.algunaOcupacionTerminaEn(ocupaciones, day);
		
		const esFeriado = this.calendarHelper.esFeriado(window.g.feriados,day);

		if(esComienzo && esFinal){
			esComienzo = false;
			esFinal = false;
		}

		const esAnteriorAHoy = moment(day).isBefore(moment(), 'day');
		const title = (esFeriado ? 'feriado - ' : '') + (estaLibre ? 'libre' : 'ocupado');

		const precioAMostrar = precioPorFecha && estaLibre ? PrecioHelper.formatearPrecio(precioPorFecha) : '';
		return <div className={stringify({block, elem:"dayContenedor"})} title={!esAnteriorAHoy  ? title : ''}>
			<div className={stringify({block, elem:"dayNumber", mods:{sinPrecio:!precioAMostrar, esComienzo, esFinal, esFeriado}})}>
				<span>{day.format('D')}</span>
			</div>
			{!esAnteriorAHoy ? <div className={stringify({block, elem:"dayPrecio"})}>
				<span>{precioAMostrar}</span>
			</div> : null}
		</div>;
	}

	renderDayTroya(day) {
		const block="CalendarioParaModal";
		const precioPorFecha = min(map(filter(this.props.precios,(p)=>{
			return moment(p.fecha).isSame(day,'day');
		}),'precio'));
		
		const estaLibre = !this.isDayBlocked(day);
		let esComienzo = this.calendarHelper.algunaOcupacionEmpiezaEn(this.props.ocupaciones, day);
		let esFinal = this.calendarHelper.algunaOcupacionTerminaEn(this.props.ocupaciones, day);
		
		const esFeriado = this.calendarHelper.esFeriado(window.g.feriados,day);

		if(esComienzo && esFinal){
			esComienzo = false;
			esFinal = false;
		}

		const esAnteriorAHoy = moment(day).isBefore(moment(), 'day');
		const title = (esFeriado ? 'feriado - ' : '') + (estaLibre ? 'libre' : 'ocupado');

		const precioAMostrar = precioPorFecha && estaLibre ? PrecioHelper.formatearPrecio(precioPorFecha) : '';
		return <div className={stringify({block, elem:"dayContenedor"})} title={!esAnteriorAHoy  ? title : ''}>
			<div className={stringify({block, elem:"dayNumber", mods:{sinPrecio:!precioAMostrar, esComienzo, esFinal, esFeriado}})}>
				<span>{day.format('D')}</span>
			</div>
			{!esAnteriorAHoy ? <div className={stringify({block, elem:"dayPrecio"})}>
				<span>{precioAMostrar}</span>
			</div> : null}
		</div>;
	}

	render(){
		const block="CalendarioParaModal";
		const startDateString = this.props.startDate ? this.props.startDate.format('DD/MM/YYYY') : 'Llegada';
		const endDateString = this.props.endDate ? this.props.endDate.format('DD/MM/YYYY') : 'Salida';
		const propsParaOcupaciones = this.props.paraOcupaciones ? {
			isDayBlocked:this.isDayBlocked,
			renderDay: this.renderDay,
			//isDayHighlighted:this.isDayHighlighted,
		} : {};

		return (
			<div className={stringify({block})} key={Math.random()}>
				<div className={stringify({block, elem:'contenedor'})}>
					<button className={stringify({block, elem:'cruz'})} onClick={this.props.ocultarModal}><RiCloseFill />{this.props.paraOcupaciones && <div className={stringify({block, elem:'texto-cerrar'})}>Cerrar</div>}</button>
					{!this.props.paraOcupaciones && <button className={`btn-noButton ${stringify({block, elem:'restablecer'})}`} onClick={this.props.onRestablecerFechas} >Restablecer</button>}
				</div>

				{!this.props.paraOcupaciones && <div className={stringify({block, elem:'contenedor'})} mods={{fechas:true}}>
					<span className={stringify({block, elem:'starDate'})} >{startDateString}</span>
					<span className={stringify({block, elem:'flecha'})} ><BsArrowRightShort /></span>
					<span className={stringify({block, elem:'endDate'})} >{endDateString}</span> 
				</div> }

				<DayPickerRangeController
					startDate={this.props.startDate}
					endDate={this.props.endDate} 
					onDatesChange={this.props.onDatesChange}
					focusedInput={this.state.focusedInput} 
					onFocusChange={this.onFocusChange}
					orientation={this.props.paraOcupaciones&&!isMobile.any?"horizontal":"verticalScrollable"}
					numberOfMonths={this.props.paraOcupaciones&&!isMobile.any?2:6}
					hideKeyboardShortcutsPanel
					daySize={this.props.paraOcupaciones&&!isMobile.any?55:38}
					isOutsideRange={this.isOutsideRange}
					minimumNights={this.props.estadia_minima}
					{...propsParaOcupaciones}
				/>
			</div>
		);
	}
}