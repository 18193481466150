import React from 'react';
import DropdownBuscadorListado from './DropdownBuscadorListado.jsx';
import AutocompleteLugares from '../Commons/AutocompleteLugares';
import ModalFechaListado from './Modales/ModalFechaListado';
import ModalCantPersonasListado from './Modales/ModalCantPersonasListado';
import moment from 'moment';
import { stringify } from 'rebem-classname';
moment.locale('es');

export default function BuscadorListado({ 
	listadoCustom,
	niños,
	adolescentes,
	jovenes,
	adultos,
	bebes,
	startDate,
	endDate,
	lugar,
	localidadSinLugar,
	busquedasRecientes,
	onLugarClear,
	onLugarSelected,
	totalPropiedades,
	areaDeEfecto,
	onAreaDeEfectoSelected,
	onDatesChange,
	onCapacidadChange,
	onRestablecerFechas,
	onPlus,
	onMinus,
	toggleMascotas,
	onRestablecerCapacidad,
	onBuscar
}) {
	const block = 'BuscadorListadoMobile';
	if(listadoCustom){
		return null;
	}

	const contPersonas = niños + adolescentes + jovenes + adultos;

	let textoCuantos = 'cuántos somos?';
	if(contPersonas){
		textoCuantos = ' ' + contPersonas + ' personas';  
	}

	let textoCuando = 'cuándo?'; 
	if(startDate && endDate) {
		textoCuando = startDate.format("DD/MM/YY") + '-' + endDate.format("DD/MM/YY");
	}
	const l = lugar || localidadSinLugar;
	const key = l ? l.id + l.tipo + l.nombre : '';
	return (
		<div className={stringify({block})}>
			<div className={stringify({block, elem:"localidad"})}>
				<div className={stringify({block, elem:"localidad-izq"})}>
					<AutocompleteLugares
						key={key}
						conIconos={false}
						lugar={l}
						busquedasRecientes={busquedasRecientes}
						onLugarClear={onLugarClear}
						onCapacidadChange={onCapacidadChange}
						onDatesChange={onDatesChange}
						onLugarSelected={onLugarSelected}
					/>
					<div className={stringify({block, elem:"leyenda"})}><b>{totalPropiedades}</b> alojamientos encontrados para vos</div>
				</div>
				<div className={stringify({block, elem:"localidad-der"})}>
					<DropdownBuscadorListado
						textoPorDefecto="Hasta 10 km"
						areaDeEfecto={areaDeEfecto}
						onAreaDeEfectoSelected={onAreaDeEfectoSelected}
						version={'mobile'}
					/>
				</div>
			</div>
			<div className={stringify({block, elem:"contenedor-buscador"})}>
				<ModalFechaListado
					urlKey="mc"
					placeholder={textoCuando}
					startDate={startDate}
					endDate={endDate}
					onDatesChange={onDatesChange}
					onRestablecerFechas={onRestablecerFechas}
				/>
				<ModalCantPersonasListado
					urlKey="ms"
					placeholder={textoCuantos}
					capacidades={{niños, adolescentes, jovenes, adultos, bebes}} 
					onPlus={onPlus}
					onMinus={onMinus}
					toggle={toggleMascotas}
					onRestablecerCapacidad={onRestablecerCapacidad}
				/>
				<button className={`btn btn-naranja ${stringify({block, elem:"buscar"})}`} onClick={onBuscar}>Buscar</button>
			</div>
		</div>
	);
}