import React from 'react';
import QSUtil from '../../helpers/QSUtil.js';
import HistoryHelper from '../../helpers/HistoryHelper.js';

/* HAY UN EJEMPLO AL FINAL */

/**
 * Este componente se puede heredar para brindar soporte al back button del navegador
 * Está pensado para que componente que lo herede sea un modal o similar, y que use el 
 * estado "visible" para contrar si se muestra o se oculta. 
 *
 * Al final del archivo hay un ejemplo de como se usa con un componente de ejemplo
 */
export class ComponentWithBackButtonSupport extends React.Component{
	//TODO:se comenta toda la logica de backButton porq no funciona en conjunto con los filtros,
	//al seleccionar un filtro y buscar, el componente se cierra y realiza un back history borrando
	//todo los filtros que acabas de seleccionar
	//hay que replantear la logica...
	constructor(props) {
		super(props);
		this.onPopState = this.onPopState.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);

		/* el estado en el hijo tiene que tener esto
		this.state = {
			primeraCarga:true,
			visible:QSUtil.includes(this.props.urlKey) //va a estar visible si su urlKey figura en la query string
		}*/
	}

	shouldBeVisible(){
		return QSUtil.includes(this.props.urlKey);
	}	

	onPopState(){
		/*this.setState({
			visible:QSUtil.includes(this.props.urlKey), 
			primeraCarga:false
		});*/
	}
	
	show() {
		this.setState({ 
			visible: true, 
			primeraCarga:false 
		}, function() {
			//HistoryHelper.push(this.props.urlKey);
		});
	}

	hide() {
		let eraPrimera = this.state.primeraCarga;
		this.setState({ visible: false, primeraCarga:false }, function() {
			//si la pantalla se abre con el modal abierto, eraPrimera viene en true
			//y el replace evita que el cerrar haga un history back, llevandonos a google
			//de nuevo
			if(eraPrimera){
				//HistoryHelper.replace(this.props.urlKey);
			}
			else{
				//history.back();
			}
		});
	}

	componentDidMount() {
		window.addEventListener("popstate", this.onPopState);
	}
	componentWillUnmount() {
		window.removeEventListener('popstate', this.onPopState);
	}
}

/* EJEMPLO */

import Dialog from '@mui/material/Dialog';
class EjemploImplementacion extends ComponentWithBackButtonSupport {
	constructor(props) {
		super(props);
		
		//no olvidar que hay que agregar estos 2 estados
		this.state = {
			primeraCarga:true,
			visible:this.shouldBeVisible(),
		};
	}
	//Esto no hace falta, pero si fueran necesarios, así es como se usan
	componentDidMount() {
		super.componentDidMount();
		//tu código
	}
	//Esto no hace falta, pero si fueran necesarios, así es como se usan
	componentWillUnmount() {
		super.componentWillUnmount();
		//tu código
	}
	render() {
		return (
			<>
				<button onClick={this.show}>show</button>
				<Dialog open={this.state.visible} onClose={this.hide}>
					<div>{this.props.urlKey}</div>
					{this.props.children}
				</Dialog>
			</>
		)
	}
}