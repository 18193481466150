import React from 'react';
import {BsChevronDown, BsArrowsAngleExpand} from 'react-icons/bs';
import ClickOutside from 'react-click-outside';
import find from 'lodash/find';
import some from 'lodash/some';
import orderBy from 'lodash/orderBy';
import concat from 'lodash/concat';
import { stringify } from 'rebem-classname';

export default class DropdownBuscadorListado extends React.Component{
	constructor(props) {
		super(props);
		this.showContenido = this.showContenido.bind(this);
		this.closeContenido = this.closeContenido.bind(this);
		this.itemSeleccionado = this.itemSeleccionado.bind(this);
		
		this.state={
			textoSeleccionado:this.props.textoPorDefecto,
			visible:false,
		}
	}	
	showContenido() {
		this.setState({ 
			visible: !this.state.visible,
		});
	}
	closeContenido(){
		this.setState({
			visible:false,
		});
	}
	getTexto(){
		if(this.props.areaDeEfecto===null || this.props.areaDeEfecto===undefined){
			return this.props.textoPorDefecto;
		}
		const aoe = this.props.areaDeEfecto.toString();
		const found = find(this.getAreasDeEfecto(), (item) => item.value===this.props.areaDeEfecto);
		if(found){
			return found.content;
		}
		else{
			return `Hasta ${aoe} km`;
		}
	}
	getAreasDeEfecto(){
		const defaults = [
			{value:0,content:'Localidad Exacta'},
			{value:1,content:'Hasta 1 km'},
			{value:5,content:'Hasta 5 km'},
			{value:10,content:'Hasta 10 km'},
			{value:20,content:'Hasta 20 km'},
			{value:30,content:'Hasta 30 km'},
			{value:50,content:'Hasta 50 km'},
			{value:100,content:'Hasta 100 km'},
		];
		const aoe = this.props.areaDeEfecto;
		const aoeInDefaults = some(defaults, (item) => item.value===aoe);
		if(!aoe || aoeInDefaults){
			return defaults;
		}

		return orderBy(concat(defaults, [{
				value:aoe,
				content:`Hasta ${aoe} km`,
			}]), ['value', 'asc']);

	}
	itemSeleccionado(evt){
		const valor = evt.currentTarget.getAttribute('data-value');
		this.props.onAreaDeEfectoSelected(parseInt(valor));
		this.closeContenido();
	}
	render(){	
		let block='DropdownBuscadorListado';
		let claseContenido = "contenido";
		let contenidoBoton = (
			<button className={`btn btn-default ${stringify({block, mods:{gly:true}})}`} onClick={this.showContenido}>
				<BsArrowsAngleExpand />
				<div className={stringify({block, elem:"contenido-texto"})}>
					<div className={stringify({block, elem:"titulo"})}>Distancia</div>
					<div className={stringify({block, elem:"valor"})}>{this.getTexto()}</div>
				</div>
			</button>
		);
		if(this.props.version == 'mobile'){
			block='DropdownBuscadorListadoMobile'
			claseContenido = "contenido-items";
			contenidoBoton = (
				<button className={`btn btn-default ${stringify({block})}`} onClick={this.showContenido}>
					<div className={stringify({block, elem:"titulo"})}>{this.getTexto()}</div>
					<div className={stringify({block, elem:"gly"})}><BsChevronDown /></div>
				</button>
			);
		}
		return (
			<ClickOutside className={block} onClickOutside={this.closeContenido}>
				{contenidoBoton}
				{	
					this.state.visible && 
					<div className={stringify({block, elem:claseContenido})}>
						{this.getAreasDeEfecto().map((item) => {
							return <div className={stringify({block, elem:"item", mods:{selected:this.props.areaDeEfecto == item.value}})} onClick={this.itemSeleccionado} key={item.value} data-value={item.value} data-content={item.content}>
								<div className={stringify({block, elem:"item-contenido"})}>{item.content}</div>
							</div>;
						})}
					</div>
				}
			</ClickOutside>
		);
	}
}