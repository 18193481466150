import React from 'react';
import 'rc-checkbox/assets/index.css';
import Checkbox from 'rc-checkbox';
import { stringify } from 'rebem-classname';

export default function SelectorCheckbox(props){
	const block="SelectorCheckbox";

	return (
		<div className={stringify({block})}>
			<div className={stringify({block, elem:'personas'})}>
				<div className={stringify({block, elem:'titulo', mods:{bold:true}})}>
					<span>{props.titulo}</span>
				</div>
			</div>
			<div className={stringify({block, elem:'cantidad_check'})}>
				<Checkbox
					checked={props.checked}
					onClick={props.toggle}
				/>
			</div>
		</div>
	);
}