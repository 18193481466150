import moment from 'moment';
import some from 'lodash/some';

export default class CalendarHelper{
	algunaOcupacionEmpiezaEn(ocupaciones, day){
		return some(ocupaciones, o => moment(o.fecha_inicio).isSame(day, 'day'));
	}
	algunaOcupacionTerminaEn(ocupaciones, day){
		return some(ocupaciones, o => moment(o.fecha_fin).isSame(day, 'day'));
	}
	esFeriado(feriados, day){
		return some(feriados, (f)=>{
			if(!f.dias || !f.dias.length){
				return false;
			}
			if(f.dias.length == 2){
				return day.isBetween(moment(f.dias[0]),moment(f.dias[1]),'day','[]');
			} else if(f.dias.length == 1){
				return moment(f.dias[0]).isSame(day,'day');
			}
		});
	}
}