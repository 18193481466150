import React from 'react';
import SelectorDePersonasParaModal from '../../Commons/SelectorDePersonasParaModal';
import Dialog from '@mui/material/Dialog';
import {ComponentWithBackButtonSupport} from '../../Abstract/ComponentWithBackButtonSupport';
import { stringify } from 'rebem-classname';

export default class ModalCantPersonas extends ComponentWithBackButtonSupport {
	constructor(props) {
		super(props);

		this.state = {
			primeraCarga:true,
			visible:this.shouldBeVisible()
		}
	}
	render() {
		const block = 'BuscadorListadoMobile';
		return (
			<div className={stringify({block, elem:"selector-modal"})}>
				<button className={`btn btn-default ${stringify({block, elem:"cantidad"})}`} onClick={this.show}> 
					<div className={stringify({block, elem:"titulo"})}>Personas</div>
					<div className={stringify({block, elem:"valor"})}>{this.props.placeholder}</div>
				</button>
				<Dialog open={this.state.visible} onClose={this.hide} fullScreen>
					<SelectorDePersonasParaModal
						onPlus={this.props.onPlus}
						onMinus={this.props.onMinus}
						onRestablecerCapacidad={this.props.onRestablecerCapacidad}
						capacidades={this.props.capacidades}
						toggle={this.props.toggle}
						ocultarModal={this.hide}
					/>
				</Dialog>
			</div>
		);
	}
}