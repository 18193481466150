import React from 'react';
import Dialog from '@mui/material/Dialog';
import {ComponentWithBackButtonSupport} from '../../Abstract/ComponentWithBackButtonSupport';
import { Tooltip, OverlayTrigger, ButtonToolbar} from 'react-bootstrap';
import CalendarioParaModal from '../../Commons/CalendarioParaModal';
import { stringify } from 'rebem-classname';

export default class ModalFechas extends ComponentWithBackButtonSupport {
	constructor(props) {
		super(props);
		this.handleHistoryMenu = true;

		this.state = {
			primeraCarga:true,
			visible:this.shouldBeVisible(),
			showTooltipFecha: !localStorage.getItem('tooltipFecha')
		}
	}

	componentDidMount(){
		let cookieTooltipFecha = localStorage.getItem('tooltipFecha');
		if (!cookieTooltipFecha) {
			localStorage.setItem('tooltipFecha', true);
		}
	}

	render(){
		const block = 'BuscadorListadoMobile';
		const tooltipFecha = (
		  <Tooltip id="tooltip" className="BuscadorListadoMobile__tooltipFecha"><strong>Para mejorar los resultados de su busqueda complete la fecha!</strong></Tooltip>
		);
		return (
			<div className={stringify({block, elem:"fecha-modal"})}>
				{(!this.props.startDate && this.state.showTooltipFecha) || (!this.props.endDate && this.state.showTooltipFecha) ? 
				<ButtonToolbar>
					<OverlayTrigger 
						placement="bottom"
						ref="tooltipFechaRef" 
						overlay={tooltipFecha}
						defaultOverlayShown
						rootClose
					> 
						<button className={`btn btn-default ${stringify({block, elem:"cuando"})}`} onClick={this.show}> 
							<div className={stringify({block, elem:"titulo"})}>Entrada / Salida</div>
							<div className={stringify({block, elem:"valor"})}>{this.props.placeholder}</div>
						</button>
					</OverlayTrigger>
				</ButtonToolbar>					
				:
				<button className={`btn btn-default ${stringify({block, elem:"cuando"})}`} onClick={this.show}> 
					<div className={stringify({block, elem:"titulo"})}>Entrada / Salida</div>
					<div className={stringify({block, elem:"valor"})}>{this.props.placeholder}</div>
				</button>}
				<Dialog open={this.state.visible} onClose={this.hide} fullScreen>
					<CalendarioParaModal
						onRestablecerFechas={this.props.onRestablecerFechas}
						startDate={this.props.startDate}
						endDate={this.props.endDate} 
						onDatesChange={this.props.onDatesChange}
						ocultarModal={this.hide}
					/>
				</Dialog>
			</div> 
		);
	}
}