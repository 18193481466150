import React from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import isMobile from 'ismobilejs';
import MediaQuery from 'react-responsive';
import reverse from 'lodash/reverse';
import map from 'lodash/map';
import sum from 'lodash/sum';
import deburr from 'lodash/deburr';
import moment from 'moment';
import { stringify } from 'rebem-classname';

export default class AutocompleteLugares extends React.Component{
	constructor(props) {
		super(props);

		this.onFocus = this.onFocus.bind(this);
		this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
		this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
		this.getSuggestionValue = this.getSuggestionValue.bind(this);
		this.onChange = this.onChange.bind(this);
		this.renderSuggestion = this.renderSuggestion.bind(this);
		this.lugarToString = this.lugarToString.bind(this);
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
		this.restablecer = this.restablecer.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.suggestionsBusquedasRecientes = this.suggestionsBusquedasRecientes.bind(this);
		this.renderSectionTitle = this.renderSectionTitle.bind(this);
		this.getSectionSuggestions = this.getSectionSuggestions.bind(this);
		this.getLugarParaSuggestion = this.getLugarParaSuggestion.bind(this);
		this.getCapacidadTotal = this.getCapacidadTotal.bind(this);
		this.getBusquedasRecientesFormateadas = this.getBusquedasRecientesFormateadas.bind(this);

		let busquedas = [];
		if (this.props.busquedasRecientes) {
			busquedas = [{ title: 'Busquedas Recientes', lugares: reverse(this.getBusquedasRecientesFormateadas(this.props.busquedasRecientes)) }];
		}

		this.state = {
			suggestions:busquedas,
			mostrarBusquedasRecientes:true,
			busquedasRecientes:busquedas,
			textoDelInput:this.props.lugar && this.props.lugar.nombre ? this.lugarToString(this.props.lugar) : '',
		}
	}
	restablecer(){
		this.setState({
			textoDelInput: ''
		}, this.props.onLugarClear()
		);
		this.props.onLugarSelected(null);
		this.autoCompleteComponent.input.focus();
	}
	onChange(event, {newValue}){
		this.setState({
			textoDelInput: newValue
		});
	};
	onSuggestionsFetchRequested({value, reason}){
		let valor;
		if(reason=="input-focused"){
			valor=""; 
		} else {
			valor=value;

			axios
			.get("/autocomplete/buscador/?q=" + valor)
			.then((result) => {
				let rst = result.data;

				this.setState({
					suggestions: !rst.length ? this.state.busquedasRecientes : rst,
					mostrarBusquedasRecientes: !rst.length
				});
			})
			.catch((err) => console.log(err));
		}
	};

	suggestionsBusquedasRecientes({value}){
		this.setState({
			suggestions: value,
			mostrarBusquedasRecientes: true
		});
	};
	renderSectionTitle(section) {
	  return (
	  	<div><strong>{section.title}</strong></div>
	  );
	}
	getSectionSuggestions(section) {
	 	return section.lugares;
	}
	
	getSuggestionValue(lugar){
		return this.lugarToString(lugar);
	}

	/**
	 * Implement it to teach Autosuggest what should be the input value when suggestion is clicked.
	 */
	onSuggestionsClearRequested(){
		this.setState({
			suggestions: this.state.busquedasRecientes,
			mostrarBusquedasRecientes: true
		});
	};

	onSuggestionSelected(event, { suggestion }){
		let s = null;
		if(suggestion){
			s = suggestion.id_alias ? {
				id:suggestion.a_id,
				tipo:suggestion.a_tipo,
				cantidad:suggestion.a_cantidad,
				nombre:suggestion.a_nombre,
				pais:suggestion.a_pais,
				provincia:suggestion.a_provincia,
				localidad:suggestion.a_localidad,
				areaDeEfecto:suggestion.a_areaDeEfecto,
				id_alias:suggestion.a_id_alias,
			} : suggestion;

			if (typeof suggestion.disponibilidad !== "undefined" && suggestion.disponibilidad) {
				this.props.onDatesChange({ 
					startDate: moment(suggestion.disponibilidad.fechaInicio), 
					endDate: moment(suggestion.disponibilidad.fechaFin)
				});
			}
			
			if (typeof suggestion.capacidad !== "undefined" && suggestion.capacidad) {	
				this.props.onCapacidadChange({
					niños: suggestion.capacidad.niños,
					bebes: suggestion.capacidad.bebes,
					adolescentes: suggestion.capacidad.adolescentes,
					jovenes: suggestion.capacidad.jovenes,
					adultos: suggestion.capacidad.adultos,
					mascotas: suggestion.capacidad.mascotas,
				});
			}
		}
		if(s){
			this.setState({
				textoDelInput:this.lugarToString(s),
			}, () => this.props.onLugarSelected(s));
		}
	}
	/**
	 * Use your imagination to define how suggestions are rendered.
	 */
	renderSuggestion(s){
		if(s.tipo==='cerca_mio'){
			return <div><strong>Cerca mío</strong></div>
		}
		if(s.tipo==='propiedad'){
			return <div>Anuncio: <strong>{s.referencia}</strong> {s.nombre}</div>
		}

		if (s.tipo === 'localidad' || s.tipo === 'provincia' || s.tipo === 'país') {
			if (s.capacidad && s.disponibilidad) {
				return (
					<div>
						{this.getLugarParaSuggestion(s)}
						<div> {this.getCapacidadTotal(s.capacidad)} personas del {moment(s.disponibilidad.fechaInicio).format("DD/MM/YY")} al {moment(s.disponibilidad.fechaFin).format("DD/MM/YY")} </div>
					</div>
				);
			} else if (s.capacidad){
				return (
					<div>
						{this.getLugarParaSuggestion(s)}
						<div> Para {this.getCapacidadTotal(s.capacidad)} personas </div>
					</div>
				);
			} else if (s.disponibilidad){
				return (
					<div>
						{this.getLugarParaSuggestion(s)}
						<div> Desde {moment(s.disponibilidad.fechaInicio).format("DD/MM/YY")} al {moment(s.disponibilidad.fechaFin).format("DD/MM/YY")} </div>
					</div>
				);
			}
		}

		return this.getLugarParaSuggestion(s);
	};

	getCapacidadTotal(capacidad) {
		const capacidadArray = map(capacidad, (c, k)=>{
			//elimino estos valores porq no cuentan en cuanto a capacidad
			if(k != 'bebes' && k != 'mascotas'){
				return parseInt(c);
			}
			return 0;
		});
		return sum(capacidadArray);
	}

	getLugarParaSuggestion(s){
		let resto = '';
		switch (s.tipo) {
			case "provincia":
				resto = ` (provincia) (${s.cantidad})`;
				break;
			case "localidad":
				resto = `, ${s.provincia || ''} (${s.cantidad})`;
				break;
			case "ciudad":
				resto = `, ${s.pais || ''} (${s.cantidad})`;
				break;
			case "barrio":
				resto = `, ${s.localidad || ''}, ${s.provincia || ''} (${s.cantidad})`;
				break;
			case "país":
				resto = ` (país) (${s.cantidad})`;
				break;
		}
		return <div><strong>{s.nombre}</strong>{resto}</div>
	}

	lugarToString(s){
		if(!s){
			return '';
		}
		if(s.tipo==='cerca_mio'){
			return 'Cerca mío';
		}
		if(s.tipo==='propiedad'){
			return 'Anuncio: ' + 	s.referencia;
		}
		let resto = '';
		switch(s.tipo){
			case "provincia":
				resto = ` (provincia)`;
				break;
			case "localidad":
				resto = `, ${s.provincia || ''}`;
				break;
			case "ciudad":
				resto = `, ${s.pais || ''}`;
				break;
			case "barrio":
				resto = `, ${s.localidad || ''}, ${s.provincia || ''}`;
				break;
			case "país":
				resto = ` (país)`;
				break;
		}
		return s.nombre + resto;
	};

	getBusquedasRecientesFormateadas(busquedasRecientes){
		return map(busquedasRecientes, (data) => {
			return {
				id: data['i'],
				tipo: data['t'],
				cantidad: data['c'],
				nombre: data['n'],
				area_defecto: data['a'],
				pais: data['p'],
				id_pais: data['ip'],
				provincia: data['pr'],
				id_provincia: data['ipr'],
				localidad: data['l'],
				id_localidad: data['il'],
				capacidad: data['ca'],
				disponibilidad: data['d'],
			};
		});
	}
	
	componentDidMount() {
		this.autoCompleteComponent.input.addEventListener("focus", this.onFocus);
	}
	componentWillReceiveProps(nextProps){
		//acutalizo el componente cuando llegan nuevas busquedas
		if (this.props.busquedasRecientes !== nextProps.busquedasRecientes) {
			this.setState({
				busquedasRecientes: [{ title: 'Busquedas Recientes', lugares: reverse(this.getBusquedasRecientesFormateadas(nextProps.busquedasRecientes)) }],
				mostrarBusquedasRecientes: true
			});
		}
	}

	onFocus(evt){
		this.suggestionsBusquedasRecientes({value:this.state.busquedasRecientes});
		//selecciona todo el texto
		this.autoCompleteComponent.input.select();
	}

	componentWillUnmount() {
		this.autoCompleteComponent.input.removeEventListener("focus", this.onFocus);
	}

	onBlur(evt, { highlightedSuggestion }){
		const { textoDelInput } = this.state;
		let hsNombre = '';
		if (highlightedSuggestion) {
			hsNombre = highlightedSuggestion ? highlightedSuggestion.nombre : '';
			if (highlightedSuggestion.tipo == 'propiedad') {
				hsNombre += ' - ' + highlightedSuggestion.referencia;
			} 
		} 
		const tInput = textoDelInput ? deburr(textoDelInput.toLowerCase()) : '';
		const tHighlighted = hsNombre ? deburr(hsNombre.toLowerCase()) : '';
		if(tInput && tHighlighted.indexOf(tInput) != -1){
			this.onSuggestionSelected(evt, {suggestion:highlightedSuggestion});
		}
	}

	render(){
		const turista = window.g.turista;
		let nombre = null;
		if(turista && turista.nombre_usuario){
			nombre = turista.nombre_usuario.split(' ');
		}
		const inputProps = {
			placeholder: ' a dónde vamos'+(nombre && nombre.length?' '+nombre[0]:'')+'?',
			value: this.state.textoDelInput,
			onChange: this.onChange,
			onBlur:this.onBlur,
		};

		const block = "AutocompleteLugares";
		return(
			<div className={stringify({block})}>
				<MediaQuery maxWidth={992}>
					<span className={`glyphicon glyphicon-map-marker ${stringify({block, elem:"iconos", mods:{iconMarker:true,conIconos:this.props.conIconos}})}`} />
				</MediaQuery>
				<Autosuggest
					suggestions={this.state.suggestions}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					onSuggestionSelected={this.onSuggestionSelected}
					getSuggestionValue={this.getSuggestionValue}
					renderSectionTitle={this.renderSectionTitle}
					renderSuggestion={this.renderSuggestion}
					multiSection={this.state.mostrarBusquedasRecientes}
					getSectionSuggestions={this.getSectionSuggestions}
					inputProps={inputProps}
					onFocus={()=>{}}
					highlightFirstSuggestion={true}
					focusInputOnSuggestionClick={!isMobile.any}
					shouldRenderSuggestions={()=>true}
					ref={(autoCompleteComponent) => this.autoCompleteComponent = autoCompleteComponent}
				/>
				<div className={stringify({block, elem:"iconos", mods:{clearInput:true,noMostrar:this.props.conIconos}})}>
					{this.state.textoDelInput!="" ? <span className="glyphicon glyphicon-remove" onClick={this.restablecer}/> : null}
				</div>
			</div>
		)
	}
}