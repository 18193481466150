import React from 'react';
import { stringify } from 'rebem-classname';
import SelectorCheckbox from './SelectorCheckbox.jsx';
import SelectorPlusMinus from './SelectorPlusMinus.jsx';

export default function SelectorCapacidadContenido(props){
	const block="SelectorCapacidadContenido";
	const propsGenericas = {
		onPlus: props.onPlus,
		onMinus: props.onMinus,
	}

	return (
		<div className={stringify({block})}>
			<SelectorPlusMinus 
				nombreProp="adultos"
				titulo="Adultos"
				leyenda="Más de 30 años"
				valor={props.capacidades.adultos} 
				{...propsGenericas}
			/>
			<SelectorPlusMinus 
				nombreProp="jovenes"
				titulo="Jóvenes"
				leyenda="De 18 a 30 años"
				valor={props.capacidades.jovenes} 
				{...propsGenericas}
			/>
			<SelectorPlusMinus 
				nombreProp="adolescentes"
				titulo="Adolescentes"
				leyenda="De 12 a 18 años"
				valor={props.capacidades.adolescentes} 
				{...propsGenericas}
			/>
			<SelectorPlusMinus 
				nombreProp="niños"
				titulo="Niños"
				leyenda="De 2 a 12 años"
				valor={props.capacidades.niños} 
				{...propsGenericas}
			/>
			<SelectorPlusMinus 
				nombreProp="bebes"
				titulo="Bebés"
				leyenda="Menos de 2 años"
				valor={props.capacidades.bebes} 
				{...propsGenericas}
			/>
			<SelectorCheckbox 
				titulo="Mascotas"
				checked={props.capacidades.mascotas}
				toggle={props.toggle}
			/>
		</div> 
	);
}